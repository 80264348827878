import { Link } from "react-router-dom"
import { enterenceImg, hvnApi, hvnUriRoot, linkNavs, shopInfo } from "../../core/shopdata"
import { navLinkMaker, h2Unlinked } from "../../helpers/blockmaker"

/* キャストidはproviderに張り付くので動的に監視・取得が勝手になされる(復習) */

const day = new Date().getFullYear()

function reserveBtn(hvnId){

    let id =""
    let linkTxt = "ネット予約"
    if(hvnId){
        id= `?girl_id=${hvnId}`
        linkTxt= "このキャストを予約"
    }

    return(
            <a className="btn_tell btn-c" href={`${hvnUriRoot}/A6ShopReservation/${id}`} target="_blank" rel="noreferrer noopner">{linkTxt}</a>
    )
}
function telBtn(tel){

    return(
            <a className="btn_tell btn-c" href={"tel:"+ tel }>電話予約</a>
    )
}


export function Footer({hvnId=null}){

    const hvnBlockTitle = ["写メ日記", "動画", "口コミ"]

    return(
        <>
        <div id="footer-stickey" className="text-center">
            { 
            /*
            <nav>
                <div>お店に電話<span>{shopInfo.tel}</span></div>
                <div><a href={hvnUriRoot+ "/reservation" + id}>予約する</a></div>
            </nav>
            */ 
            }
        </div>
        <footer id="footer-global">
                {h2Unlinked("写メ日記")}
                <iframe className="frame-hvn diary" src={hvnApi.Diary}></iframe>
                {h2Unlinked("動画")}
                <iframe className="frame-hvn" src={hvnApi.Video}></iframe>
                {h2Unlinked("口コミ")}
                <iframe className="frame-hvn" src={hvnApi.Comment}></iframe>

           <nav className="common-bg">
                <ul>
                    {linkNavs.map(navLinkMaker)}
                </ul>
           </nav>
           <div className="common-bg">
                <div className="first text-center">
                    <div className="text-center end">
                        <a href="https://men.marineblue-g.com">
                            <img className="" src="/img/20231019-s-rec.jpg" alt="男女スタッフ大募集！ 店舗スタッフ未経験時給50万円～ 経験者月給80万円～" />
                        </a>
                    </div>
                    <div className="text-center end">
                        <a href="https://www.girlsheaven-job.net/ibaraki/ma-164/sa-425/marineblue-tennis/?of=y">
                            <img className="" src="/img/job_b.jpg" alt="女の子求人コーナー 採用率99.9％脅威の19部屋完備の新店舗！" />
                        </a>
                    </div>
                    <a href="/home">
                        <img className="logo-sm" src="/img/paris-tsuchiura-logo.png" />
                    </a>
                </div>
           </div>
            <div className="footer_fixed">
                <div id="reserve_btn">
                    {telBtn(shopInfo.tel)}
                    {reserveBtn(hvnId)}
                </div>
            </div>
            <section className="copylight">
                <p className="text-center">&copy; { day } {shopInfo.name }<br className="sp" />All rights reserved.</p>
            </section>
        </footer>
        </>
    )
}

export default Footer