import { areaName, enterenceImg, shopInfo } from "../core/shopdata"
import { Link } from "react-router-dom"


export function Enterence(){

    return(
        <main id="enterence">
            
            <article className="text-center">
                <header>
                    <div className="first text-primary"><img className="logo-sm" src="/img/paris-tsuchiura-logo.png" alt={`${areaName}のソープランド【${shopInfo.name}`}/></div>
                    <h1><img src="https://api.marineblue-g.com/api/topbanner?t=teniss_marineblue" alt={areaName + 'のソープランド【' + shopInfo.name + `】のイメージバナー`} /></h1>
                </header>

                <p className="kin-txt">
                    <img src="/img/18txt.png" width="61" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                </p>  
                <p className="alert kin-txt"> 
                    当サイトはアダルトコンテンツを含みます。<br/>
                    18歳未満の方のご利用は固く禁じられています。<br/>
                    <br/>
                    18歳未満の方は<a href="https://www.yahoo.co.jp/" className="underline">コチラ</a>からご退出ください
                </p>

                <div className="attention-container">
                    <div className="attention-box">
                        当店は暴力団を含む反社会的団体<br/>
                        及びスカウト等との関わりは一切ございません。
                    </div>
                    <div class="attention-wrap">
                        <div class="title">■加盟協会・団体</div>
                        <ul className="group-box">
                            <li>・全日本特殊浴場協会連合会</li>
                            <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                            <li>・一般社団法人全国防犯健全協力会</li>
                            <li>・東京防犯健全協力会</li>                        
                        </ul>
                    </div>
                </div>


                <div>
                    <Link to="/home" className="btn-enter"><span>ENTER</span><br />18歳以上のお客様ページ</Link>
                </div>

            </article>
            <section className="text-center">
                <div className="bnr">
                    <a href="https://marineblue-g.com/" target="_blank" rel="norefarrer noopner">
                        <img src="https://marineblue-g.com/img/bnr-group_20240701.jpg" width="640px" alt="マリングループ" />
                    </a>
                </div>
                <div className="bnr">
                    <a href="https://www.cityheaven.net/ibaraki/A0802/A080202/marineblue-tennis/">
                        <img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ヘブン版はこちら.png" width="640px" />
                    </a>
                </div>
                <div className="bnr">
                    <a href="https://www.girlsheaven-job.net/ibaraki/ma-164/sa-425/marineblue-tennis/?of=y">
                        <img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ガールズ版はこちら.png" width="640px" />
                    </a>
                </div>
                <div className="bnr">
                    <a href="https://mensheaven.jp/17/marineblue-tennis/?of=y">
                        <img src="https://img.cityheaven.net/img/kikaku/kikaku/wp-content/uploads/2023/05/ジョブ版はこちら.png" width="640px" />
                    </a>
                </div>
                <div className="bnr">
                    <p style={{textAlign: "center", margin: 0}}>
                        <a rel="nofollow" href="https://kitakanto.qzin.jp/teniss/?v=official" target="_blank">
                            <img src="https://ad.qzin.jp/img/bnr_sp_sample_vanilla.jpg" width="640" border="0" alt="テニスマリンブルーで稼ぐならバニラ求人" />
                        </a>
                    </p>
                </div>
                <div className="bnr">
                    <p style={{textAlign: "center", margin: 0}}>
                        <a href="https://www.cityheaven.net/ibaraki/A0802/A080202/shop-list/biz4/" target="_blank">
                            <img src="https://img.cityheaven.net/img/mutual_link/468_68_29.jpg" width="468" height="68" border="0" alt="土浦のソープ｜シティヘブンネット" />
                        </a>
                    </p>
                </div>
                <div className="bnr">
                    <p style={{textAlign: "center", margin: 0}}>
                        <a href="https://www.girlsheaven-job.net/ibaraki/ma-164/sa-425/" target="_blank">
                            <img src="https://img.girlsheaven-job.net/img/fppc/468_68_29.png" width="468" height="68" border="0" alt="土浦の風俗求人｜ガールズヘブン" />
                        </a>
                    </p>
                </div>
                <div className="bnr">
                    <p style={{textAlign: "center", margin: 0}}>
                        <a href="https://mensheaven.jp/16/ibaraki/ma-164/sa-425/shop-list/" target="_blank">
                            <img src="https://img.mensheaven.jp/img/fppc/bnr/047.jpg" width="468" height="68" border="0" alt="土浦のスタッフ求人｜ジョブヘブン" />
                        </a>
                    </p>
                </div>
                <div className="bnr">
                    <p style={{textAlign: "center", margin: 0}}>
                        <a href="https://ranking-deli.jp/fuzoku/style5/13/area77/" target="_blank">
                            <img src="https://ranking-deli.jp/assets/img/user/link/2018bnr01.jpg" width="468" height="60" border="0" alt="土浦のソープを探すなら[駅ちか]" />
                        </a>
                    </p>
                </div>
                <div className="bnr">
                    <p style={{textAlign: "center", margin: 0}}>
                        <a href="https://yarowork.jp/kanto/search/?pref%5B%5D=8" target="_blank"><img src="https://yarowork.jp/img/banner_link/yaroworkbnr_468x60.jpg" alt="茨城県の高収入求人情報 野郎WORK" /></a>
                    </p>
                </div>
            </section>
        </main>
    )

}

export default Enterence()