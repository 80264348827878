import { Link } from "react-router-dom"
import { linkNavs } from "../../core/shopdata"
import {navLinkMaker} from "../../helpers/blockmaker"
/* ナビの色変え案
    pathの先頭トリムしてidに割り当てればpath取得して対応・一意化が可.
*/

const blockNav = linkNavs.slice(0,4)
const textNav = linkNavs.slice(4)

export function Header(){

    return(
        <header id="header-global">
            <h1 className="text-center">
                <a href="/home">
                    <img className="logo-sm" src="/img/paris-tsuchiura-logo.png" />
                </a>
            </h1>
            <nav id="g-nav-stickey">
                <ul id="header-block-nav">
                    {blockNav.map(navLinkMaker)}
                </ul>
                <div className="devider" />
                <ul id="header-text-nav">
                    {textNav.map(navLinkMaker)}
                </ul>
            </nav>

        </header>
    )
}

export default Header()
