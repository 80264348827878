import { useEffect } from 'react'
import { useRoutes, useLocation} from 'react-router-dom'

// import { Home } from './pages'

// pagefiles. 後でインデックスファイル作ってそこから一括importに変える(見通し確保)
import { Enterence } from './pages/enterence'
import Home  from './pages/home'
import Casts from './pages/casts'
import NewFace from './pages/newface'
import Profile from './pages/profile'
import { Access } from './pages/access'
import { System } from './pages/system'
import { Schedule } from './pages/schedule'

import { Footer } from "./pages/components/footer"
import { Header } from "./pages/components/header"

import ScheduleDataProvider from './service/scheduleData'
import CastDiaryDataProvider from './service/castDiary'
import { useCastData } from './service/castData'

function App() {

  const {hvnId} =useCastData()

  let element = useRoutes([
    { path: "/", element: <Enterence /> },
    { path: "/home", element: <ScheduleDataProvider><Home /></ScheduleDataProvider>},
    { path: "/schedule", element: <ScheduleDataProvider><Schedule /></ScheduleDataProvider>},
    { path: "/schedule/:selectedDate", element: <ScheduleDataProvider><Schedule /></ScheduleDataProvider>},
    { path: "/newface", element: <NewFace />},
    { path: "/access", element: <Access />},
    { path: "/system", element: <System />},
    { path: "/casts", element: <Casts />},
    { path: "casts/:castId", element: <CastDiaryDataProvider><Profile /></CastDiaryDataProvider>}
  ])

  const location = useLocation().pathname

  const ScrollTop=()=>{
  
    
    useEffect(()=>{
      window.scrollTo(0,0)
  
    },[location])
  
    return null
  }



  if(location === "/"){
    return(
      <div id='container' >
        {element}
      </div>
    )
  }
  else{
    return (
      <>
        <ScrollTop />
        <div id='container' >
          <Header />
            { element }
          <Footer hvnId={hvnId} />
        </div>
      </>
    )
  }
}

export default App;
