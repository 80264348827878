import { useEffect } from "react"
import { castCard, h2Unlinked } from "../helpers/blockmaker"

import { useCastData } from "../service/castData"

export default function Casts(){

    const {newfaceData, updateNewface } = useCastData()

    useEffect(()=> updateNewface(),[])
    console.log(newfaceData)

    // const d = updateCasts()

    return(
        <main id="casts">
            {h2Unlinked("新人情報")}
            {newfaceData.length
                ?   <ul className="box-casts ">
                        {newfaceData.map(casts => {
                            return <li>{castCard(casts)}</li>
                        })}
                    </ul>
                : <p className="text-center" style={ {marginBottom:50} }>登録されていません</p>
            }
        </main>
    )
}
