import { useContext, useEffect } from "react"
import { sampleData } from "../core/shopdata"
import { castCard, h2Unlinked } from "../helpers/blockmaker"

import { useCastData } from "../service/castData"

export default function Casts(){

    const {castData, updateCasts } = useCastData()

    useEffect(()=> updateCasts(),[])

    // const d = updateCasts()

    return(
        <main id="casts">
            {h2Unlinked("在籍")}
            {castData.length
                ?   <ul className="box-casts ">
                        {castData.map(casts => {
                            return <li>{castCard(casts)}</li>
                        })}
                    </ul>
                : <p className="text-center" style={ {marginBottom:50} }>登録されていません</p>
            }
        </main>
    )
}
