import { useEffect } from "react";
import { sampleData, shopInfo, slideArr } from "../core/shopdata"
import { h2Unlinked, calender, makeCalenderLink, castCard } from "../helpers/blockmaker"

import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";

import { useCastData } from "../service/castData";
import { useScheduleData } from "../service/scheduleData";

function slideMaker(obj){
    if(obj.link.uri !== null){
        if(obj.link.blank !== false ){
            return <SwiperSlide><a href={obj.link.uri} target="_blank" rel="noreferrer noopner"><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></a></SwiperSlide> }
            
        else{
            return <SwiperSlide><a href={obj.link.uri}><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></a></SwiperSlide> }
    }

    else{
        return <SwiperSlide><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></SwiperSlide> }
}

function limitedSlide(slideData, 
                      endDateFromat, 
                      startDateFromat=null){

    // console.log(slideData)
    
    const now = new Date()
    const end = new Date(endDateFromat)

    // 表示開始日時の指定あり
    if(startDateFromat !== null) {
        const start = new Date(startDateFromat)

        if(start.getTime() < now.getTime() &&
           now.getTime() < end.getTime()){
            
            return slideMaker(slideData)
        }
    }

    // 開始の指定なし (期日まで常設)
    else if(now.getTime() < end.getTime()){

        return slideMaker(slideData)
    }
}


export default function Home(){

    const {castData, updateCasts, 
           newfaceData,updateNewface} = useCastData()

    const {scheduleData, updateSchedule} = useScheduleData()

    useEffect(()=> {
        updateCasts()
        updateNewface()
        updateSchedule()
    },[])


    console.log(castData)
    console.log(newfaceData)
    console.log(scheduleData)

    // 出勤スライス用
    let nf = newfaceData


    let nowDay = new Date().getDay()

    return(
        <main id="home">
            <header>
                <Swiper
                            modules={[Autoplay]}
                            loop={true}
                            slidesPerView={1}
                            autoplay={true}
                            centeredSlides={true}
                            // breakpoints={{ 560:{slidesPerView:3},}}
                        >
                        {slideArr.map(s => slideMaker(s))}
                </Swiper>
            </header>
            <section className="box-schedule">
                {h2Unlinked("出勤")}
                <nav className="box-calender">{makeCalenderLink(calender(), nowDay)}</nav>
                {scheduleData.length
                    ? <ul className="box-casts ">
                            {scheduleData.map(casts => {
                                return <li>{castCard(casts)}</li>
                            })}
                        </ul>
                    : <p className="text-center" style={ {marginBottom:50} }>出勤予定はありません</p>

                }

            </section>
            <section>
                {h2Unlinked("新人情報")}
                {nf.length
                    ?   <ul className="box-casts ">
                            {nf.slice(0,9).map(casts => {
                                    return <li>{castCard(casts)}</li>
                            })}
                        </ul>
                    :   <p className="text-center" style={ {marginBottom:50} }>登録されていません</p>
                }
            </section>
        </main>
    )
}