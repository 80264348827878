import React, {createContext, useState, useContext} from "react"
import axios from "axios"

import { apiRoot } from "../core/shopdata"

const newApiRoot = "https://api.marineblue-g.com"


// キャスト個人ページの写メ日記取得. 取得元apiは後日他も一緒に纏める
// (api側終わってないので、取り急ぎ写メ日記のみ)
const CastDiaryContext = createContext()
export const useCastDiaryData =()=>useContext(CastDiaryContext)

export default function CastDiaryDataProvider({children}){
    const [castDiaryData, setCastDiaryData] = useState([])

    const getCastDiary =(hvnDiaryUrl)=>{
        
        axios({
            method: "get",
            url: `${newApiRoot}/api/diary_cast?t=` + hvnDiaryUrl
        })
        .then((res=>{
            // console.log(res)
            setCastDiaryData(res.data)
        }))
    }


    return(
        <CastDiaryContext.Provider value={{
            castDiaryData, getCastDiary
        }}>
            {children}
        </CastDiaryContext.Provider>
    )
}