import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { h2Unlinked, calender, makeCalenderLink, castCard } from "../helpers/blockmaker"

import { useScheduleData } from "../service/scheduleData"

export function Schedule(){

    const {scheduleData, updateSchedule} = useScheduleData()

    const { selectedDate } = useParams()
    console.log(selectedDate)
    
    let selectedDay = new Date().getDay()
    if(selectedDate !== undefined){
        selectedDay = selectedDate.slice(-1)

    }

    useEffect(()=> {
        updateSchedule(selectedDate)
    },[selectedDate])

    console.log(scheduleData)

    return(
        <main id="schedule">
            {h2Unlinked("出勤")}
            <section className="box-schedule">
                <nav className="box-calender">{makeCalenderLink(calender(), selectedDay)}</nav>
                {scheduleData.length
                    ?   <ul className="box-casts ">
                            {scheduleData.map(casts => {
                                return <li>{castCard(casts)}</li>
                            })}
                        </ul>
                    : <p className="text-center" style={ {marginBottom:50} }>出勤予定はありません</p>
                }
            </section>
        </main>
    )
}

export default Schedule