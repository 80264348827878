import React, { createContext, useState, useContext, useEffect} from "react"
import axios from "axios"

import { apiRoot } from "../core/shopdata"


// キャスト一覧,新人の2項
// 出勤・プロフィールは分離 (後者はprofileページでしか使わないのでそちらに載せる)

const ScheduleDataContext = createContext()

export const useScheduleData = () => useContext(ScheduleDataContext)

export default function ScheduleDataProvider({ children }){

    const [scheduleData, setScheduleData] = useState([])

    // 再取得処理 (re-fetch)
    const updateSchedule = (dateParam) =>{

        let target = `${apiRoot}/schedule.php`
        if(dateParam !== undefined){
            target += `?d=${dateParam}`}

        axios({
            method: "get",
            url: target
        })
        .then((res => {
            // console.log(res)
            // 一旦これ. 初回データセットでfetchするからその関数呼び出しでいいでしょう
            setScheduleData(
                res.data
            )
        }))
    }


    return(
        <ScheduleDataContext.Provider value={{ scheduleData, updateSchedule }}>
            {children}
        </ScheduleDataContext.Provider>
    )
}
